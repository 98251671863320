.fill-height {
   /* width: 500px; */
   /* height: 500px; */
   width: 100%;
   height: 100%;
   /* height: calc(100vh - 100px); */
}

.blocklyTreeIcon {
   background-image: url("/public/sprites.png");
}
.blocklyMainBackground {
   /* stroke: red; */
   stroke-width: 0; /* 框線 */
}
/* 
.blocklyText {
   fill: red !important;  將文字顏色設定為紅色 
} 
*/
