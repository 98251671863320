.HomeImage {
   max-height: 40vmin;
   pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .HomeImage {
    animation: HomeImage-spin infinite 5s linear;
  }
}

@keyframes HomeImage-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
